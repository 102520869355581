import * as ActionTypes from "./qcbot.actiontypes";

export const QCBOT_LOAD_PALLETS = (startDate, endDate, dcId, halfPallets, fullPallets, selectAll, userToken) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLETS,
    payload: { startDate, endDate, dcId, halfPallets, fullPallets, selectAll, userToken },
  };
};
export const QCBOT_LOAD_PALLETS_SUCCESS = (data) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLETS_SUCCESS,
    payload: data,
  };
};
export const QCBOT_LOAD_PALLETS_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLETS_FAILURE,
    error: error,
  };
};
export const QCBOT_LOAD_PALLET_DETAIL = (inferenceId, dcId) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLET_DETAIL,
    payload: { inferenceId, dcId },
  };
};
export const QCBOT_LOAD_PALLET_DETAIL_SUCCESS = (data) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLET_DETAIL_SUCCESS,
    payload: data,
  };
};
export const QCBOT_LOAD_PALLET_DETAIL_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_LOAD_PALLET_DETAIL_FAILURE,
    error: error,
  };
};
export const QCBOT_LOAD_CUSTOMERS = () => {
  return {
    type: ActionTypes.QCBOT_LOAD_CUSTOMERS,
    payload: {},
  };
};
export const QCBOT_LOAD_CUSTOMERS_SUCCESS = (data) => {
  return {
    type: ActionTypes.QCBOT_LOAD_CUSTOMERS_SUCCESS,
    payload: data,
  };
};
export const QCBOT_LOAD_CUSTOMERS_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_LOAD_CUSTOMERS_FAILURE,
    error: error,
  };
};
export const QCBOT_AUDIT_PALLET = (inferenceId, dcId, data) => {
  return {
    type: ActionTypes.QCBOT_AUDIT_PALLET,
    payload: { inferenceId, dcId, data },
  };
};
export const QCBOT_AUDIT_PALLET_SUCCESS = () => {
  return {
    type: ActionTypes.QCBOT_AUDIT_PALLET_SUCCESS,
    payload: {},
  };
};
export const QCBOT_AUDIT_PALLET_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_AUDIT_PALLET_FAILURE,
    error: error,
  };
};
export const QCBOT_SET_LOADING = (value) => {
  return {
    type: ActionTypes.QCBOT_SET_LOADING,
    payload: value,
  };
};
export const QCBOT_CONFIRM_AUDIT = (inferenceId, dcId, selectedIsPickErr) => {
  return {
    type: ActionTypes.QCBOT_CONFIRM_AUDIT,
    payload: { inferenceId, dcId, selectedIsPickErr },
  };
};
export const QCBOT_CONFIRM_AUDIT_SUCCESS = () => {
  return {
    type: ActionTypes.QCBOT_CONFIRM_AUDIT_SUCCESS,
    payload: {},
  };
};
export const QCBOT_CONFIRM_AUDIT_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_CONFIRM_AUDIT_FAILURE,
    error: error,
  };
};
export const QCBOT_LIST_CUSTOMER_SKUS = (customerId) => {
  return {
    type: ActionTypes.QCBOT_LIST_CUSTOMER_SKUS,
    payload: { customerId },
  };
};
export const QCBOT_LIST_CUSTOMER_SKUS_SUCCESS = (data) => {
  return {
    type: ActionTypes.QCBOT_LIST_CUSTOMER_SKUS_SUCCESS,
    payload: data,
  };
};
export const QCBOT_LIST_CUSTOMER_SKUS_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_LIST_CUSTOMER_SKUS_FAILURE,
    error: error,
  };
};
export const QCBOT_ADD_EXTRA_SKU = (data) => {
  return {
    type: ActionTypes.QCBOT_ADD_EXTRA_SKU,
    payload: data,
  };
};
export const QCBOT_ADD_EXTRA_SKU_SUCCESS = () => {
  return {
    type: ActionTypes.QCBOT_ADD_EXTRA_SKU_SUCCESS,
    payload: {},
  };
};
export const QCBOT_ADD_EXTRA_SKU_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_ADD_EXTRA_SKU_FAILURE,
    error: error,
  };
};

export const QCBOT_UPDATE_PALLETS_NOT_SELECTED = (error) => {
  return {
    type: ActionTypes.QCBOT_UPDATE_PALLETS_NOT_SELECTED,
    error: error,
  };
};

export const QCBOT_GET_INFERENCE_ID = (ssccId, dcId) => {
  return {
    type: ActionTypes.QCBOT_GET_INFERENCE_ID,
    payload: {ssccId, dcId},
  };
};

export const QCBOT_GET_INFERENCE_ID_SUCCESS = (data) => {
  return {
    type: ActionTypes.QCBOT_GET_INFERENCE_ID_SUCCESS,
    payload: {data},
  };
};
export const QCBOT_GET_INFERENCE_ID_FAILURE = (error) => {
  return {
    type: ActionTypes.QCBOT_GET_INFERENCE_ID_FAILURE,
    error: error,
  };
};