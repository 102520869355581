export const QCBOT_LOAD_PALLETS = "[LOAD PALLETS] Load Pallets";
export const QCBOT_LOAD_PALLETS_SUCCESS = "[LOAD PALLETS] Load Pallets Success";
export const QCBOT_LOAD_PALLETS_FAILURE = "[LOAD PALLETS] Load Pallets Failure";
export const QCBOT_LOAD_PALLET_DETAIL =
  "[LOAD PALLET DETAIL] Load Pallet Detail";
export const QCBOT_LOAD_PALLET_DETAIL_SUCCESS =
  "[LOAD PALLET DETAIL] Load Pallet Detail Success";
export const QCBOT_LOAD_PALLET_DETAIL_FAILURE =
  "[LOAD PALLET DETAIL] Load Pallet Detail Failure";
export const QCBOT_LOAD_CUSTOMERS = "[LOAD CUSTOMERS] Load Customers";
export const QCBOT_LOAD_CUSTOMERS_SUCCESS =
  "[LOAD CUSTOMERS] Load Customers Success";
export const QCBOT_LOAD_CUSTOMERS_FAILURE =
  "[LOAD CUSTOMERS] Load Customers Failure";
export const QCBOT_AUDIT_PALLET = "[AUDIT PALLET] Audit Pallets";
export const QCBOT_AUDIT_PALLET_SUCCESS =
  "[AUDIT PALLET] Audit Pallets Success";
export const QCBOT_AUDIT_PALLET_FAILURE =
  "[AUDIT PALLET] Audit Pallets Failure";
export const QCBOT_SET_LOADING = "[GLOBAL] Set Loading";
export const QCBOT_CONFIRM_AUDIT = "[CONFIRM AUDIT] Confirm Audit";
export const QCBOT_CONFIRM_AUDIT_SUCCESS =
  "[CONFIRM AUDIT] Confirm Audit Success";
export const QCBOT_CONFIRM_AUDIT_FAILURE =
  "[CONFIRM AUDIT] Confirm Audit Failure";
export const QCBOT_LIST_CUSTOMER_SKUS = "[CUSTOMER SKUS] List Customer SKUs";
export const QCBOT_LIST_CUSTOMER_SKUS_SUCCESS =
  "[CUSTOMER SKUS] List Customer SKUs Success";
export const QCBOT_LIST_CUSTOMER_SKUS_FAILURE =
  "[CUSTOMER SKUS] List Customer SKUs Failure";
export const QCBOT_ADD_EXTRA_SKU = "[EXTRA SKU] Add Extra SKU";
export const QCBOT_ADD_EXTRA_SKU_SUCCESS = "[EXTRA SKU] Add Extra SKU Success";
export const QCBOT_ADD_EXTRA_SKU_FAILURE = "[EXTRA SKU] Add Extra SKU Failure";
export const QCBOT_UPDATE_PALLETS_NOT_SELECTED = "[QCBOT_UPDATE_PALLETS_NOT_SELECTED]";

export const QCBOT_GET_INFERENCE_ID = "[GET INFERENCE ID] Get Inference ID";
export const QCBOT_GET_INFERENCE_ID_SUCCESS = "[GET INFERENCE ID SUCCESS] Get Inference ID Success";
export const QCBOT_GET_INFERENCE_ID_FAILURE = "[GET INFERENCE ID FAILURE] Get Inference ID Failure";