import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import * as QCBotActions from "../../store/qcbot/qcbot.actions";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import {

    CircularProgress,
  } from "@material-ui/core";


  const SSCCPalletDetailHandler = (props) => {

    const { sscc_id, dc_id } = useParams();
    const {
        fetchInferanceDetails,
        loading,
        inferenceDetailsError,
        inferanceDetail,
      } = props;

    useEffect(() => {
        if (loading === false && inferenceDetailsError === null && inferanceDetail.inferanceId === null) {
          fetchInferanceDetails(sscc_id, dc_id);
        }
      }, [loading]);

    return (
        <>
            {props.loading && (
                <div className="loader-overlay">
                <CircularProgress size={80} />
                </div>
            )}
            {props.inferenceDetailsError &&(
                 <div>
                 <Snackbar open={true} autoHideDuration={5000} onClose={() => {
                            //    this.handleClose()
                           }} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                   <MuiAlert elevation={6} variant="filled" severity="error" sx={{ width: '100%', backgroundColor:"#d32f2f !important", color:"#fff !important" }}>
                   {inferenceDetailsError}
                   </MuiAlert>
                 </Snackbar>
              </div>
            )}
    </>
    )
  }

  const mapStateToProps = (state) => {
    return {
      loading: state.inferanceDetailsLoading,
      inferenceDetailsError: state.inferenceDetailsError,
      inferanceDetail: state.inferanceDetail,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchInferanceDetails: (ssccId, dcId) =>
        dispatch(QCBotActions.QCBOT_GET_INFERENCE_ID(ssccId, dcId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SSCCPalletDetailHandler);