import React, { useEffect, useRef, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const ImageViewer = ({ src, boundaries, color }) => {
  const imageRef = useRef(null);
  const [refWidth, setRefWidth] = useState(0);
  const [status, setStatus] = useState({
    scale: 0,
    translation: {
      x: 0,
      y: 0,
    },
  });

  let imgwrapper = document.getElementsByClassName(
    "image-viewer-container"
  )[0];
  useEffect(() => {
    if (src && imageRef?.current) {
      if ((refWidth > 720) && (imgwrapper.clientHeight > 600)) {
        setStatus({
          scale: 0.2,
          translation: {
            x: 420,
            y: 0,
          },
        })
      }
      else if ((refWidth > 720) && (imgwrapper.clientHeight >= 560)) {
        setStatus({
          scale: 0.2,
          translation: {
            x: 390,
            y: 0,
          },
        })
      }
      else if ((refWidth > 720) && (imgwrapper.clientHeight < 560)) {
        setStatus({
          scale: 0.2,
          translation: {
            x: 260,
            y: 0,
          },
        })
      }
      else if ((refWidth <= 720) && (imgwrapper.clientHeight > 600)) {
        setStatus({
          scale: 0.7,
          translation: {
            x: 380,
            y: 0,
          },
        })
      }
      else if ((refWidth <= 720) && (imgwrapper.clientHeight > 560)) {
        setStatus({
          scale: 0.7,
          translation: {
            x: 350,
            y: 0,
          },
        })
      }
      else {
        setStatus({
          scale: 0.7,
          translation: {
            x: 220,
            y: 0,
          },
        })
      }
    }
  }, [refWidth, imageRef, src])

  const getFullImage = () => {
    var h = window.height;
    var w = window.width;
    let openDoc = window.open('', "_blank", `'scrollbars=yes, resizable=yes', width= ${w} + height= ${h}`);

    let body = `
    <!DOCTYPE html>
    <html> 
    <style>
     html{overflow: auto}
     body{height: auto}
    </style>
            <body style={{height: 'auto'}} id="bodyinner">
              <img src=${src} alt='fullImage'/>
             </body>
            </html>`
    function callBack() {
      openDoc.document.querySelector('body');
      openDoc.focus();
      openDoc.scroll({
        top: 100,
        left: 100,
        behavior: "smooth",
      });

    }
    return openDoc.document.write(body, callBack());
  }

  const onImageLoad = () => {
    if (imageRef?.current) {
      setRefWidth(imageRef?.current.naturalWidth);
      const translation = { ...status.translation };
      const wrapper = document.getElementsByClassName(
        "image-viewer-container"
      )[0];
      translation.x =
        (((window.innerWidth - 72) * 2) / 3 -
          18 -
          imageRef?.current.naturalWidth * status.scale) /
        2;

      translation.y = -(
        (imageRef?.current.naturalHeight * status.scale -
          wrapper.clientHeight) /
        2
      );

      setStatus({ ...status, translation });
    }
  };

  return (
    <>
      {src ?
        <div style={{
          zIndex: '999', position: 'absolute', left: '55.7%', top: '28.5%',
          cursor: 'pointer', color: 'white', display: 'inline-flex', backgroundColor: '#ccc', padding: '5px', borderRadius: '5px'
        }}
          onClick={getFullImage}
        >
          Expand Image <AspectRatioIcon sx={{ color: 'white', marginLeft: '4px' }} />
        </div>
        : ''}


      <MapInteractionCSS
        minScale={0.5}
        maxScale={3}
        value={status}
        onChange={(value) => {
          setStatus(value);
        }}
      >
        <div className="image-viewer-wrapper">
          <img
            src={src}
            className="pallet-detail-image"
            alt="palletImage"
            ref={imageRef}
            onLoad={onImageLoad}
          />

        {!!boundaries && (
          <div className="overlay-drawer">
            {Object.values(boundaries)
              .flat()
              .map((boundary, id) => (
                <div
                  className="overlay"
                  style={{
                    border: `5px solid ${color}`,
                    left: boundary.xMin,
                    top: boundary.yMin,
                    width: boundary.xMax - boundary.xMin - 10,
                    height: boundary.yMax - boundary.yMin - 10,
                  }}
                  key={`boundary${id}`}
                />
              ))}
          </div>
        )}
      </div>
    </MapInteractionCSS>
    </>
  );
};

export default ImageViewer;
