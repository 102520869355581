import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import "./App.css";
import {Amplify} from "aws-amplify";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducer from "./store/qcbot/qcbot.reducer";
import { EffectListener } from "./store/qcbot/qcbot.effects";
import createSagaMiddleware from "redux-saga";

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTHORITY,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
      responseType: "code"
    }
  }
}
Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(EffectListener);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
