import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { AppBar, Toolbar, Button, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { checkAccess } from "../../../services/qcbot.service";
import { SESSION_KEY } from "../../../helpers";
import { useState } from "react";
import ShowModal from "../../modal/modal";

const Navbar = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [showModal, setShowModal] = useState(false);
  const onLogOut = () => {
    localStorage.removeItem(SESSION_KEY);
    Auth.signOut()
      .then(() => {
        localStorage.removeItem(SESSION_KEY);
        window.location.replace('/');
      })
      .catch((err) => {
        console.log("Error on Logout:", err.message);
      });
  };
  const AccessDenied = () => {
    setShowModal(true);
    setTimeout(() => {
      localStorage.removeItem(SESSION_KEY);
      Auth.signOut()
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log("Error on Logout:", err.message);
        });
      setShowModal(false);
    },3000);
  }

  const checkCentralUserAuth = async () => {
    let userData = await checkAccess();
    if (userData?.has_access === true) {
      console.log("Access Granted");    
    }
    else {
      AccessDenied();
    }
  }

  const checkUserAccess = () => {
    if (user?.attributes["custom:central_role_id"] === "1" || user?.attributes["custom:central_role_id"] === "2") {
      console.log("Access Granted");
    }else{
      checkCentralUserAuth();
    }
  }
  useEffect(() => {
    checkUserAccess();
    // eslint-disable-next-line
  }, [user]);

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: onLogOut,
    debounce: 1000,
  });

  return (
    <div>
      {showModal ? <ShowModal /> : ""}
      <AppBar position="static" className="appbar">
        <Toolbar className="navbar">
          <a href="/">
            <Typography variant="h6">QC BOT</Typography>
          </a>

          <Button color="inherit" onClick={onLogOut}>
            LOGOUT
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
