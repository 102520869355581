import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import ErrorImage from "../../../assets/image-placeholder.png";
import './styles.css';

const PalletListItem = ({ data }) => {
  const onMediaFallback = (event) => {
    event.target.src = ErrorImage;
  };

  return (
    <div>
      <Card className="pallet-list-item">
        <CardActionArea href={`/pallet/${data.inference_id}/${data.dc_id}`}>
          <CardMedia
            className="item-media"
            component="img"
            image={data.s3_folder_url || ErrorImage}
            title="Pallet"
            onError={onMediaFallback}
          />
          <CardContent className="card-content">
            <Typography gutterBottom variant="body1">
              <Box component="span" fontWeight='bold'>ROUTE - </Box>
              {data.local_route}
            </Typography>
            <Typography gutterBottom variant="body1">
              <Box component="span" fontWeight='bold'>STORE - </Box>
              {data.store_name}
            </Typography>
            <Typography gutterBottom variant="body1">
              <Box component="span" fontWeight='bold'>PALLET ID - </Box>
              {data.customer_picklist_id}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default PalletListItem;
