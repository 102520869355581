import {
  Typography,
  FormControl,
  Select,
  CircularProgress,

} from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useMemo, useState, useLayoutEffect } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import * as QCBotActions from "../../../store/qcbot/qcbot.actions";
import PalletListItem from "../PalletListItem/PalletListItem";
import { SESSION_KEY } from "../../../helpers";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAuthenticator } from '@aws-amplify/ui-react';

const PalletListPage = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { fetchPallets, palletData, fetchCustomers, customers, loading, updatePalletsLength } = props;
  const [selectedDate, setSelectedDate] = useState(moment().add(1, "days"));
  const [customerId, setCustomerId] = useState("");
  const [dcId, setDcId] = useState("");
  const [isSessionRestored, setSessionRestored] = useState(false);
  const [halfPallets, setHalfPallets] = useState(false);
  const [fullPallets, setFullPallets] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#fff"
  });

  const dcData = useMemo(() => {
    return customers.reduce((result, item) => {
      result[item.customer_id] = item.distribution_centers;
      return result;
    }, {});
  }, [customers]);

  useEffect(() => {
    if (fetchCustomers) fetchCustomers();
  }, [fetchCustomers]);


  useEffect(() => {
    if (!isSessionRestored) {
      if(selectedDate && dcId) {
        setHalfPallets(true);
        setSessionRestored(true)
      }else{
       setHalfPallets(false);
       setSessionRestored(false)
      }
    }
   }, [selectedDate, dcId]);

   const toReview = [];
    const audited = [];

   const FetcFullPallets = (event) => {
    if (event.target.checked) {
      setFullPallets(true);
    } else {
      setFullPallets(false);
    }
  }
  const FetcHalfPallets = (event) => {
    if (event.target.checked) {
      setHalfPallets(true);
    } else {
      setHalfPallets(false);
    }
  }
  const FetchAll = (event) => {
    if (event.target.checked) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }

  useEffect(() => {
    if ((fetchPallets && selectedDate, dcId)) {
      localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({
          selectedDate,
          customerId,
          dcId,
          selectAll,
          fullPallets,
          halfPallets
        })
      );

        if (halfPallets !== false || fullPallets !== false || selectAll !== false) {
          fetchPallets(
            selectedDate.format("YYYY-MM-DD"),
            selectedDate.clone().add(1, "days").format("YYYY-MM-DD"),
            dcId,
            halfPallets,
            fullPallets,
            selectAll
          );
        } else {
          updatePalletsLength() 
       }
      
    }
  }, [fetchPallets, selectedDate, dcId, halfPallets, fullPallets, selectAll]);

  useEffect(() => {
    try {
      const restoredSession = JSON.parse(localStorage.getItem(SESSION_KEY));
      setSelectedDate(moment(restoredSession.selectedDate));
      setDcId(restoredSession.dcId);
      setCustomerId(restoredSession.customerId);
      setSelectAll(restoredSession.selectAll);
      setFullPallets(restoredSession.fullPallets);
      setHalfPallets(restoredSession.halfPallets);
      setSessionRestored(true)
    } catch (err) {
      setSessionRestored(false)
      console.log("No session");
    }
  }, []);

  if (!palletData) return null;

  palletData.forEach((pallet) => {
    if (!!pallet.is_qc_bot_audited) audited.push(pallet);
    else toReview.push(pallet);
  });

  return (
    <div className="container">
      <div className="pallet-list-customers">
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <div className="date-picker-container">
            <h5>DELIVERY DATE: </h5>

            <KeyboardDatePicker
              variant="inline"
              disableToolbar
              inputVariant="outlined"
              format="MMMM D"
              InputAdornmentProps={{ position: "start" }}
              className="date-picker"
              value={selectedDate}
              onChange={setSelectedDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disabled: true,
              }}
            />
          </div>
        </MuiPickersUtilsProvider>

        <div className="pallet-list-custom-select-container">
          <h5>CUSTOMER:</h5>

          <FormControl variant="outlined" className="select-form-cont">
            <Select
              native
              value={customerId}
              onChange={(e) => {
                setCustomerId(e.target.value);
                setDcId("");
              }}
              className="pallet-list-custom-select"
            >
              <option value="" disabled>
                Select Customer
              </option>
              {customers.map((customer) => (
                <option
                  value={customer.customer_id}
                  key={`customer_option_${customer.customer_id}`}
                >
                  {customer.customer_name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="pallet-list-custom-select-container">
          <h5>DISTRIBUTION CENTER:</h5>

          <FormControl variant="outlined" className="select-form-cont">
            <Select
              native
              value={dcId}
              onChange={(e) => setDcId(e.target.value)}
              className="pallet-list-custom-select"
            >
              <option value="" disabled>
                Select Distribution Center
              </option>
              {customerId &&
                dcData[customerId]?.map((item) => (
                  <option value={item.dc_id} key={`dc_item_${item.dc_id}`}>
                    {item.dc_name}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>
      
      </div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <div style={{ marginBottom: '20px',}}>
              <FormControlLabel
                sx={{margin: '30px 25px 0px 0px' }}
                value="HalfPallets"
                control={<Checkbox style={{ marginLeft: "5px" }} />}
                label="Half pallets needing audit"
                labelPlacement="start"
                checked={halfPallets}
                onChange={FetcHalfPallets}
              />
              <FormControlLabel
                sx={{ margin: '30px 25px 0px 0px' }}
                value="FullPallets"
                control={<Checkbox style={{ marginLeft: "5px" }} />}
                label="Full pallets needing audit"
                labelPlacement="start"
                checked={fullPallets}
                onChange={FetcFullPallets}
              />
              <FormControlLabel
                sx={{ margin: '30px 0px 0px 0px' }}
                value="selectAll"
                control={<Checkbox style={{ marginLeft: "5px" }} />}
                label="Pallets not designated for audit"
                labelPlacement="start"
                checked={selectAll}
                onChange={FetchAll}
              />
              <div style={{clear: 'both'}}></div>
            </div>
       </div>
      {loading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          {!palletData.length && customerId && dcId && (
            <div className="loader-container">
              <Typography variant="h6">No Pallets Found</Typography>
            </div>
          )}

          {!!toReview.length && (
            <>
              <div className="pallet-list-headline">
                <Typography variant="h6">PALLETS TO REVIEW</Typography>
                <div className="pallet-headline-divider" />
              </div>
              <div className="pallet-item-list">
                {toReview.map((item) => (
                  <PalletListItem
                    key={item.customer_picklist_id + item.picklist_system_id}
                    data={item}
                  />
                ))}
              </div>
            </>
          )}

          {!!audited.length && (
            <>
              <div className="pallet-list-headline">
                <Typography variant="h6">AUDITED PALLETS</Typography>
                <div className="pallet-headline-divider" />
              </div>
              <div className="pallet-item-list">
                {audited.map((item) => (
                  <PalletListItem
                    key={item.customer_picklist_id + item.picklist_system_id}
                    data={item}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    palletData: state.pallets,
    customers: state.customers,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPallets: (startDate, endDate, dcId, halfPallets, fullPallets, selectAll, userToken = "") =>
      dispatch(
        QCBotActions.QCBOT_LOAD_PALLETS(startDate, endDate, dcId, halfPallets, fullPallets, selectAll, userToken)
      ),
    fetchCustomers: () => {
      dispatch(QCBotActions.QCBOT_LOAD_CUSTOMERS());
    },
    updatePalletsLength: () => {
      dispatch(QCBotActions.QCBOT_UPDATE_PALLETS_NOT_SELECTED());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PalletListPage);
