import axios from "axios";
import { Auth } from "aws-amplify";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const CNETRAL_AUTH_ENDPOINT = `${API_ENDPOINT}/Central`;

const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.accessToken.jwtToken;
  } catch {
    return null;
  }
};

export const getPallets = async (startDate, endDate, dcId, halfPallets, fullPallets, selectAll, authToken = "") => {
  const token = await getToken();
  var params = null;
  if (halfPallets === false && fullPallets === false && selectAll === false) {
     params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId
    }
  }else if(halfPallets === true && fullPallets === false && selectAll === false){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "half_pallets": halfPallets
    }

  }else if(halfPallets === false && fullPallets === true && selectAll === false){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "full_pallets": fullPallets
    }

  }else if(halfPallets === true && fullPallets === false && selectAll === true){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "half_pallets": halfPallets,
      "all_pallets": selectAll
    }

  }else if(halfPallets === false && fullPallets === true && selectAll === true){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "full_pallets": fullPallets,
      "all_pallets": selectAll
    }

  }else if(halfPallets === false && fullPallets === false && selectAll === true){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "all_pallets": selectAll
    }

  }else if(halfPallets === true && fullPallets === true && selectAll === false){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "half_pallets": halfPallets,
      "full_pallets": fullPallets,
    }

  }else if(halfPallets === true && fullPallets === true && selectAll === true){
    params = {
      start_date: startDate,
      end_date: endDate,
      dc_id: dcId,
      "half_pallets": halfPallets,
      "full_pallets": fullPallets,
      "all_pallets": selectAll
    }

  } else {}

  return axios.post(
    API_ENDPOINT + "/qcbot/list_pallets", params,
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getPalletDetail = async (inferenceId, dcId) => {
  const token = await getToken();
  return axios.post(
    API_ENDPOINT + "/qcbot/pallet_inferene_details",
    {
      inference_id: inferenceId,
      dc_id: dcId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getCustomers = async () => {
  const token = await getToken();
  return axios.get(API_ENDPOINT + "/qcbot/get_customers", {
    headers: {
      Authorization: token,
    },
  });
};

export const getSKUPhotos = async (upc, referenceNumber) => {
  const token = await getToken();
  return axios.get(API_ENDPOINT + `/qcbot/sku_photos/${upc}/${referenceNumber}`, {
    headers: {
      Authorization: token,
    },
  });
};

export const auditPallet = async (inferenceId, dcId, data) => {
  const token = await getToken();
  return axios.post(
    API_ENDPOINT + "/qcbot/audit_pallet",
    {
      inference_id: inferenceId,
      dc_id: dcId,
      skus_to_update: data,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const confirmAudit = async (inferenceId, dcId, selectedIsPickErr) => {
  const token = await getToken();
  return axios.put(
    API_ENDPOINT + "/qcbot/confirm_audit",
    {
      inference_id: inferenceId,
      dc_id: dcId,
      //"is_picker_err": selectedIsPickErr
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getCustomersSKUs = async (customerId) => {
  const token = await getToken();
  return axios.get(API_ENDPOINT + "/qcbot/list_customer_skus/" + customerId, {
    headers: {
      Authorization: token,
    },
  });
};

export const addExtraSku = async (data) => {
  const token = await getToken();
  return axios.put(API_ENDPOINT + "/qcbot/insert_extra_sku", data, {
    headers: {
      Authorization: token,
    },
  });
};


export const checkAccess = async () => {
  const token = await getToken();
  return axios.get(CNETRAL_AUTH_ENDPOINT + "/auth/application/7", {
    headers: {
      Authorization: token,
    },
  }).then(response => {
    return response.data
  })
    .catch(error => {
      return error
    });;
};

export const getInferenceDetail = async (ssccId, dcId) => {
  const token = await getToken();
  return axios.post(
    API_ENDPOINT + "/qcbot/sscc_inference_details",
    {
      sscc: ssccId,
      dc_id: dcId,
    },
    {
      headers: {
        Authorization: token,
      },
      mode: 'no-cors',
    }
  );
};
